@import "theme/css/colors.scss";

* {
  font-family: "AvenirLTStd-Book", sans-serif;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5em 2em;
  color: $color-gray;
  line-height: 1.5rem;
  min-height: 10em;

  @media (max-width: 992px) {
    min-height: unset;
    padding: 5em 1em;
  }

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1.6rem;
    font-family: "AvenirLTStd-Black", sans-serif;
    font-weight: bold;
    color: $color-primary;
  }

  p {
    font-size: 1.2rem;
  }
}

.toolbar {
  button.btnBackOffice {
    color: #fff;
    text-transform: "none";
  }
  @media (max-width: 761.9px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    button.btnBackOffice {
      color: #fff;
      width: 100%;
      margin: 1em auto;
    }
  }
}

#info {
  height: 10em;
  background-color: $color-white;
  .content {
    max-width: 17em;

    h2 {
      font-weight: bold;
    }
    h2,
    p {
      text-align: center;
      margin: 0.3em 0;
    }
  }
}

#conheca {
  background-color: $color-bg-gray;
  text-align: right;
  min-height: 20em;
  .content {
    max-width: 33.75em;

    img {
      width: 18em;
      margin-bottom: 1em;
    }

    h2,
    p {
      margin: 0;
      color: $color-gray;
    }
    .top-images {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: 374px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
      .Tag01 {
        // width: 113.91px;
        // height: 113.91px;
        max-width: 9em;
        // height: 10em;
      }
      .Image01 {
        width: 100%;
        max-width: 16em;
      }
    }
  }
}

#missao {
  background-color: $color-bg-dark-gray;
  text-align: center;

  .content {
    max-width: 33.75em;
  }

  .item {
    margin: 2em 0;
  }

  h2,
  p {
    color: $color-gray;
    margin: 0;
  }
}

#vantagens {
  text-align: right;
  background-color: $color-bg-gray;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 761.9px) {
        flex-direction: column-reverse;
      }

      .text {
        max-width: 30em;
        margin-top: -6.3em;

        @media (min-width: 992px) {
          margin-left: -3em;
        }

        @media (max-width: 761.9px) {
          margin-top: 0;
          text-align: justify;
        }
      }

      .text,
      .ilustration {
        flex: 1;
      }
    }

    .ilustration-head {
      img {
        width: 29.625em;
        // height: 1em;
        @media (max-width: 737px) {
          width: 100%;
          display: none;
        }
      }
    }
    .ilustration-head-mobile {
      padding: 0;
      margin: 0;
      img {
        width: 29.625em;
        max-width: 100%;
        // height: 1em;
        @media (min-width: 737px) {
          display: none;
        }
      }
    }

    .ilustration {
      margin: 2em 0 0 1em;
    }

    .ilustration img {
      width: 100%;
      max-width: 51.875em;
    }

    h2,
    p {
      margin: 0;
      color: $color-gray;
    }
  }
}

#barra-imagens {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  // width: 100%;
  min-height: 20em;

  background: url("../../theme/img/barra-imagens.svg") no-repeat center;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#seguranca {
  display: flex;
  background-color: $color-bg-gray;
  text-align: right;

  .content {
    @media (max-width: 761.9px) {
      display: flex;
      flex-direction: column;
    }

    .header {
      .image03 {
        width: 100%;
        max-width: 16em;
      }
    }

    .info {
      display: flex;
      align-items: flex-start;
      @media (max-width: 761.9px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .LockerImg {
        width: 10em;
        margin-top: -160px;

        @media (max-width: 761.9px) {
          display: none;
        }
      }

      .text {
        margin-left: -55px;
        width: 100%;
        max-width: 26.49125em;

        @media (max-width: 761.9px) {
          margin-left: unset;
        }
        h2,
        p {
          margin: 0;
          color: $color-gray;
          @media (max-width: 761.9px) {
            text-align: left;
          }
        }
      }
    }
  }
}

#aspectos-legais {
  background-color: $color-white;
  text-align: right;
  min-height: 20em;
  .content {
    display: flex;
    align-items: flex-end;
    flex-direction: row;

    @media (max-width: 761.9px) {
      align-items: flex-start;
      flex-direction: column-reverse;
      .column01,
      .column02 {
        text-align: left;
      }
    }

    .column01 {
      flex: 2;
    }
    .column02 {
      flex: 1;
      margin-left: 1em;
      @media (max-width: 761.9px) {
        margin-bottom: 1em;
        margin-left: unset;
      }
    }

    .column01 {
      p {
        max-width: 23em;
      }
      img {
        width: 18em;
      }

      h2,
      p {
        margin: 0;
        color: $color-gray;
      }
    }

    .column02 {
      img {
        width: 100%;
        max-width: 16em;
      }
    }
  }
}

#contato {
  height: 10em;
  text-align: center;
  background-color: $color-bg-blue;
  h2,
  p,
  button {
    color: $color-white;
  }
}

#footer {
  color: #fff;
  background-color: #000;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 761.9px) {
    flex-direction: column;
    .contact {
      width: 100%;
    }
  }

  padding: 2em;
  color: #fff;

  .contact {
    a,
    p {
      margin: 0;
      margin-bottom: 5px;
      text-decoration: none;
    }
  }
}
