@import "./fonts.scss";

html,
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #282c34; */
  font-family: "AvenirLTStd-Medium", sans-serif;
}
