@font-face {
  font-family: "AvenirLTStd-Black";
  src: url("../fonts/AvenirLTStd-Black.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTStd-Book";
  src: url("../fonts/AvenirLTStd-Book.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTStd-Medium";
  src: url("../fonts/AvenirLTStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url("../fonts/AvenirLTStd-Roman.otf") format("opentype");
}
