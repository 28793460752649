.banner {
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  min-height: 80vh;

  background: url("../../theme/img/banner/CAPA@2x.jpg") no-repeat top left;

  @media (max-width: 761.9px) {
    min-height: 30vh;
    background: url("../../theme/img/banner/CAPA.jpg") no-repeat top left fixed;
  }

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
